import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DropdownMenu from './dropdownMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './webHeader.css';
import { useAuth } from '../../statemanagement/authContext.js'; 
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebase.js';
import { useStateValue } from '../../statemanagement/StateProvider.js';
import { actionTypes } from '../../statemanagement/reducer.js';
import Logo from '../logo/logo.js';
import { MenuItem, Select } from '@mui/material';

const WebHeader = () => {
    const navigate = useNavigate();
    const { user, loading } = useAuth(); 
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [menuState, setMenuState] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1038);
    const [{language}, dispatch] = useStateValue();
    const [openSubMenus, setOpenSubMenus] = useState({});

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1038);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuOpen = (event, menu) => {
        setMenuState({ ...menuState, [menu]: event.currentTarget });
    };

        const menuItems = [
        { text: 'Home', href: '/' },
        {
            text: 'Kundli', subMenu: [
                { text: 'Free Kundli', href: '/free-kundli' },
                { text: 'Marriage Kundli Matching', href: '/marriage-kundli-matching' },
                { text: 'How to read Kundli', href: '/how-to-read-kundli' },
            ]
        },
        {
            text: 'Horoscope', subMenu: [
                { text: 'Today Horoscope', href: '/today-horoscope' },
                { text: 'Tomorrow Horoscope', href: '/tomorrow-horoscope' },
                { text: 'Weekly Horoscope', href: '/weekly-horoscope' },
                { text: 'Monthly Horoscope', href: '/monthly-horoscope' },
                { text: 'Yearly Horoscope', href: '/yearly-horoscope' },
                { text: 'Couple Horoscope', href: '/couple-horoscope' },
                { text: 'Marriage Horoscope', href: '/marriage-horoscope' },
                { text: 'Career & Business Horoscope', href: '/career-business-horoscope' },
                { text: 'Wealth & Fortune Horoscope', href: '/wealth-fortune-horoscope' }
            ]
        },
        {
            text: 'Calculators', subMenu: [
                { text: 'Love Calculator', href: '/love-calculator' },
                { text: 'Age Calculator', href: '/age-calculator' },
                { text: 'Career Calculator', href: '/career-calculator' },
                { text: 'Lucky Dates Calculator', href: '/lucky-dates-calculator' },
                { text: 'Lucky Rudraksha Calculator', href: '/lucky-rudraksha-calculator' },
                { text: 'Baby Name Generator', href: '/baby-name-calculator' },
            ]
        },
        {
            text: 'Predictions', subMenu: [
                { text: 'Daily Predictions', href: '/daily-prediction' },
                { text: 'Weekly Predictions', href: '/weekly-prediction' },
                { text: 'Numerology Predictions', href: '/numerology-prediction' },
                { text: 'Gemstone Recommendation', href: '/gemstones-predictions' },
                { text: 'Marriage Prediction by DoB', href: '/marriage-prediction-by-date-of-birth' },
                { text: 'Wealth Prediction by DoB', href: '/wealth-prediction-by-date-of-birth' },
                { text: 'Health Prediction by DoB', href: '/health-prediction-by-date-of-birth' },
            ]
        },
        {
            text: 'Transits', subMenu: [
                { text: 'Rahu Ketu Transits', href: '/rahu-ketu-transits' },
                { text: 'Saturn Transits', href: '/saturn-transits' },
                { text: 'Jupiter Transits', href: '/jupiter-transits' },
            ]
        },
        // {
        //     text: 'AstroSky Store', subMenu: [
        //         { text: 'Online Puja Booking', href: '/online-puja-booking' },
        //         { text: 'Gemstones online', href: '/gemstones-online' },
        //         { text: 'Rudraksha online', href: '/rudraksha-online' },
        //         { text: 'Kawach', href: 'kawach-online' },
        //         { text: 'Fengshue', href: '/fengshue-online' },
        //         { text: 'Reiki Healing', href: '/reiki-healing-online' },
        //         { text: 'Bracelets & Pendants', href: '/bracelets-pendants-online' },
        //         { text: 'Palmistry', href: '/palmistry-online' },
        //         { text: 'Name Correction', href: '/name-correction-numerology' },
        //     ]
        // },
        {
            text: 'Misc', subMenu: [
                { text: 'Blogs', href: '/blogs' },
                // { text: 'Tarot', href: '/tarot' },
                // { text: 'Celebrity Horoscopes', href: '/celebrity-horoscopes' },
                // { text: 'Hindu Festivals 2024', href: '/hindu-festivals' },
                // { text: 'Customer Reviews', href: '/customer-reviews' },
            ]
        },
    ];

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleNavigate = (href) => {
        navigate(href);
    };

    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleClickLogin = () => {
        navigate("/auth/login"); 
    }

    const handleLogout = async () => {
        try {
            await signOut(auth);
            dispatch({ type: actionTypes.SET_UID, uid: null });
            dispatch({ type: actionTypes.SET_USER_CRED, userCred: null });
            dispatch({ type: actionTypes.SET_USER, user: null });
            localStorage.removeItem('uid');
            localStorage.removeItem('userCred');
            localStorage.removeItem('user');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        dispatch({ type: actionTypes.SET_LANGUAGE, language: newLanguage });
        localStorage.setItem('language', newLanguage);
    };

    const toggleSubMenu = (menuName) => {
        setOpenSubMenus(prevState => ({
            ...prevState,
            [menuName]: !prevState[menuName]
        }));
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className="web-header">
            <AppBar position="static" className="top-header">
                <Toolbar className="header-content">
                    <Logo/>

                    {!isMobile && (
                        <div className="header-actions">
                            <Button variant="contained" className="action-button" onClick={() => { navigate("/") }}>Chat with Astrologer</Button>
                            {user ? (
                                <Button variant="contained" className="action-button" onClick={handleLogout}>Logout</Button>
                            ) : (
                                <Button variant="contained" className="action-button" onClick={handleClickLogin}>Login</Button>
                            )}                       
                        </div>
                    )}
                    
                    {isMobile && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' } }}
                            onClick={handleToggleDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>

            {!isMobile && (
                <div className="bottom-header">
                    <nav className="nav-links">
                        {menuItems.map((item) => {
                            if (item.subMenu) {
                                return (
                                    <div key={item.text}>
                                        <DropdownMenu key={item.text} title={item.text} items={item.subMenu} />
                                    </div>
                                );
                            } else {
                                return (
                                    <a key={item.text} href={item.href}>{item.text}</a>
                                );
                            }
                        })}
                    </nav>
                    <Select
                        value={language}
                        onChange={handleLanguageChange}
                        className="language-select"
                    >
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="hi">हिंदी</MenuItem>
                        <MenuItem value="te">తెలుగు</MenuItem>
                    </Select>
                </div>
            )}

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <div
                    role="presentation"
                >
                    <div className="drawer-header">
                        <img src='../../../icons/horoscope.png' alt="Logo" className="drawer-logo" />
                        <div className="logo-text-container">
                            <Typography variant="h6" className="logo-text">Hi Astro</Typography>
                        </div>
                    </div>
                    <Divider />
                     <List>
                    {menuItems.map((item) => (
                        item.subMenu ? (
                            <div key={item.text}>
                                <ListItem button onClick={(event) => { 
                                    event.stopPropagation(); // Prevent event bubbling
                                    toggleSubMenu(item.text);
                                }}>
                                    <ListItemText primary={item.text} />
                                    <ArrowDropDownIcon />
                                </ListItem>
                                {openSubMenus[item.text] && (
                                    <List component="div" disablePadding className="drawer-submenu">
                                        {item.subMenu.map((subItem) => (
                                            <ListItem button key={subItem.text} onClick={(event) => {
                                                event.stopPropagation(); // Prevent event bubbling
                                                handleNavigate(subItem.href);
                                            }}>
                                                <ListItemText primary={subItem.text} style={{ paddingLeft: '30px' }} />
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </div>
                        ) : (
                            <ListItem button key={item.text} onClick={(event) => {
                                event.stopPropagation(); // Prevent event bubbling
                                handleNavigate(item.href);
                            }}>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        )
                    ))}
                </List>
                    <Select
                        value={language}
                        onChange={handleLanguageChange}
                        className="language-select"
                    >
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="hi">हिंदी</MenuItem>
                        <MenuItem value="te">తెలుగు</MenuItem>
                    </Select>
                </div>
            </Drawer>
        </div>
    );
};

export default WebHeader;

