import React, { useRef, useEffect, useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { actionTypes } from '../statemanagement/reducer';

const HorizontalScrollButtons = ({ app_category, dispatch, horoscope }) => {
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const checkScrollButtons = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  useEffect(() => {
    checkScrollButtons();
  }, []);

  const buttonStyles = {
    margin: '0 10px',
    textWrap:"nowrap",
    textTransform: "none",
    '&.MuiButton-root:active': {
      boxShadow: 'none',
    },
    '&.Mui-focusVisible': {
      boxShadow: 'none',
    },
  };

  const getView = () => {
    const baseViews = ['All', 'Marriage Predictions', 'Love Predictions', 'Life Coach', 'Career Predictions', 'Kundli Matching', 'Wealth', 'Live', 'Numerology', 'Vastu', 'Feng Shui'];
    //'Kundli Matching',
    //const baseViews = ['All', 'Marriage Predictions', 'Career Predictions', 'Love Predictions', 'Wealth Predictions', 'Personal Reports', 'Live Predictions', 'Numerology', 'Vastu', 'Feng Shui'];
    // if (horoscope) {
    //     baseViews.splice(2, 0, 'Weekly Horoscope'); // Insert at index 2
    // }
    return baseViews;
};


  const getLinkPath = (text) => {
    switch (text) {
      case 'Love Predictions':
        return '/love-predictions';
      case 'Personal Reports':
        return '/personal-reports';
      case 'Career Predictions':
        return '/career-predictions';
      case 'Live':
        return '/live';
      case 'Numerology':
        return '/numerology';
      case 'Vastu':
        return '/vastu';
      case 'Feng Shui':
        return '/feng-shui'; 
      case 'Wealth':
        return '/wealth';
      case 'Marriage Predictions':
        return '/marriage-predictions';
      case 'New':
        return '/new-entities'
      case 'All':
        return '/'; 
      case 'Kundli Matching':
        return '/kundli-matching';
      case 'Life Coach':
        return '/life-coach';
      default:
        return '/'; 
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: "5%" }}>
      {showLeftArrow && (
        <IconButton onClick={() => scroll(-200)} sx={{ visibility: showLeftArrow ? 'visible' : 'hidden' }}>
          <ArrowBackIosIcon sx={{ color: "black !important" }} />
        </IconButton>
      )}
      <Box
        ref={scrollRef}
        onScroll={checkScrollButtons}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollbarWidth: 'none', // Hide scrollbar for Firefox
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome, Safari, and Edge
        }}
      >
        {getView().map((text, index) => {
          //const min = text === 'All' ? "60px" : "max-content";
          const linkPath = getLinkPath(text);
          return (
            <React.Fragment key={index}>
              {linkPath ? (
                <Link to={linkPath} style={{ textDecoration: 'none' }}>
                  <Button
                    onClick={() => {console.log("app_category: ", text); dispatch({ type: actionTypes.SET_APP_CATEGORY, app_category: text })}}
                    sx={{
                     ...buttonStyles,
                      background: app_category === text ? "black !important" : "whitesmoke",
                      color: app_category === text ? "white" : "#0F0F0F !important",
                      borderRadius: "11px"
                    }}
                  >
                    {text}
                  </Button>
                </Link>
              ) : (
                <Button
                  onClick={() => dispatch({ type: actionTypes.SET_APP_CATEGORY, app_category: text })}
                  sx={{
                    ...buttonStyles,
                //n    minWidth: min,
                    background: app_category === text ? "black !important" : "whitesmoke",
                    color: app_category === text ? "white" : "#0F0F0F !important",
                    borderRadius: "11px",
                    
                  }}
                >
                  {text}
                </Button>
              )}
            </React.Fragment>
          );
        })}
      </Box>
      {showRightArrow && (
        <IconButton onClick={() => scroll(200)} sx={{ visibility: showRightArrow ? 'visible' : 'hidden' }}>
          <ArrowForwardIosIcon sx={{ color: "black !important" }} />
        </IconButton>
      )}
    </Box>
  );
};

export default HorizontalScrollButtons;
