import { useEffect, useState } from 'react';
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import dayjs from 'dayjs';
import "./BasicChat.css"
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

const MobileDateComponent = ({setHasEditedDateTime,edit=false}) => {
  const [{ userCred, dob, tob }, dispatch] = useStateValue();
  
  const defaultDateTime = () => {
    if(dob && tob){

      const [day, month, year] = dob.split('/');
      const [hours, minutes] = tob.split(':');

      return dayjs(`${year}-${month}-${day}T${hours}:${minutes}`)
    }
    else{
      return dayjs();
    }
  } 
  
  const [dateValue, setDateValue] = useState(defaultDateTime());


  //const [dateValue, setDateValue] = useState(dayjs(null));
  //2022-04-17T18:30
  

  const getHour = (h) => {
    if(h<10){
        return `0${h}`;
    }
    return h;
  }

  const getMin = (m) => {
    if(m<10){
        return `0${m}`;
    }
    return m;
  }

  const padToTwoDigits = (number) => {
    return number.toString().padStart(2, '0');
  };

  useEffect(()=>{
      dispatch({
        type: actionTypes.SET_USER_DOB,
        dob: `${padToTwoDigits(dateValue.date())}/${padToTwoDigits(dateValue.month() + 1)}/${dateValue.year()}`
      });
      dispatch({
        type: actionTypes.SET_USER_TOB,
        tob: `${getHour(dateValue.hour())}:${getMin(dateValue.minute())}`
      });
      setHasEditedDateTime(true);
  }, [dateValue, dispatch, setHasEditedDateTime]);

  useEffect(() => {

    if (dob && tob) {
      const [day, month, year] = dob.split('/');
      const [hours, minutes] = tob.split(':');
      setDateValue(dayjs(`${year}-${month}-${day}T${hours}:${minutes}`));
    }
  }, [dob, tob]);

  return (
    <div style={{marginTop:"3%", marginBottom:"8.5%", fontSize:"large"}} >
         { !edit && <p style={{fontWeight:"500"}}>Enter Your Birth Date and Time</p>} 
         { edit && <p style={{fontWeight:"500"}}>Enter Your Birth Date and Time</p>}
          <br/>
          <MobileDateTimePicker 
            style={{width:"93vw"}} 
            value={dateValue} 
            onChange={(newDateValue) => {
              setDateValue(newDateValue);
            }} 
          />        
    </div>
  );
}

export default MobileDateComponent;