import React, { useEffect, useRef, useState } from 'react';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import useAIService from "../helpers/useAIService";
import "./Results.css";
import "./BasicChat.css"
import Refine from "../components/AskFollowUp";
import { useNavigate } from "react-router-dom"
import AtomicSpinner from 'atomic-spinner';
import "./BasicChat.css"
import RightSidebar from '../components/RightSidebar';
import { getImageSrc } from './Mode_Util/util_mode';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Helmet } from 'react-helmet';


function Results() {

  const [{ term, followup, conversationHistory, loading, streaming, nextQuestion, chatMode, user, subscription, mode, entity, dob, tob, city }, dispatch] = useStateValue();
  const refToScrollableDiv = useRef(null);
  useAIService(term, followup, refToScrollableDiv, user, subscription);


  const displayConversationHistory = JSON.parse(JSON.stringify(conversationHistory));
  // console.log(displayConversationHistory);

  const [autoScroll, setAutoScroll] = useState(true);

  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  const formattedEntityName = (entity) => entity.charAt(0).toUpperCase() + entity.slice(1)

  // Function to get and format the current time with AM/PM
  const getCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    // Convert 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 hour should be shown as 12

    return `${hours}:${minutes} ${ampm}`;
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    const monthName = date.toLocaleString('default', { month: 'long' });
    return `${parseInt(day)}${getOrdinal(day)} ${monthName} ${year}`;
};

const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return (s[(v - 20) % 10] || s[v] || s[0]);
};

  useEffect(() => {

    if (conversationHistory.length < 2) return;

    if (!refToScrollableDiv.current) return;

    if (autoScroll && streaming) {
      const { scrollHeight } = refToScrollableDiv.current;
      refToScrollableDiv.current.scrollTo(0, scrollHeight);
    }
    if (followup.length > 0) {
      const { scrollHeight } = refToScrollableDiv.current;
      refToScrollableDiv.current.scrollTo(0, scrollHeight);
    }

  }, [conversationHistory, autoScroll, followup]);

  useEffect(() => {
    if (term.length > 0 || followup.length > 0) setAutoScroll(true);
  }, [term, followup])

  useEffect(() => {
    const scrollContainer = refToScrollableDiv.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("mousedown", () => setAutoScroll(false));
      scrollContainer.addEventListener("touchstart", () => setAutoScroll(false));
      scrollContainer.addEventListener("click", () => setAutoScroll(false));
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("mousedown", () => setAutoScroll(false));
        scrollContainer.removeEventListener("touchstart", () => setAutoScroll(false));
        scrollContainer.removeEventListener("click", () => setAutoScroll(false));
      }
    }

  }, [])

  const entityName = (entity == "default") ? "Guruji" : entity;
  const status = streaming ? "Typing..." : "online"

  const getServiceAndRelatedAspect = (entityName) => {
    const serviceMap = {
      pooja: { service: 'marriage', aspect: 'marriage' },
      arun: { service: 'marriage', aspect: 'marriage' },
      sonam: { service: 'marriage', aspect: 'marriage' },
      vivek: { service: 'career', aspect: 'career' },
      chandrikaa: { service: 'career', aspect: 'career' },
      chitra: { service: 'career', aspect: 'career' },
      prem: { service: 'love', aspect: 'love' },
      priyanka: { service: 'love', aspect: 'love' },
      ivana: { service: 'life path', aspect: 'life path' },
      vikram: { service: 'vedic', aspect: 'vedic' },
      hemant: { service: 'wealth', aspect: 'wealth' },
      vidya: { service: 'numerology', aspect: 'numerology' },
      venkat: { service: 'vedic', aspect: 'vedic' },
      karan: { service: 'Feng Shui', aspect: 'feng shui' },
      vijayalakshmi: { service: 'vedic', aspect: 'vedic' },
      moniika: { service: 'love', aspect: 'love' },
      naina: { service: 'love', aspect: 'love' },
      madhvacharya: { service: 'vastu', aspect: 'vastu' },
      raj: { service: 'vedic', aspect: 'vedic' },
      aditya: { service: 'numerology', aspect: 'numerology' },
      ashok: { service: 'life coach', aspect: 'life coach' },
      guruji: { service: 'vedic', aspect: 'vedic life' },
      romila: { service: 'life coach', aspect: 'life coach' },
      sunny: { service: 'personality', aspect: 'personality' },
      preeti: { service: 'vedic', aspect: 'vedic' },
      raviraj: { service: 'numerology', aspect: 'numerology' }
    };

    return serviceMap[entityName.toLowerCase()] || { service: 'astrology', aspect: 'life' };
  };

  const getMetaTags = () => {
    const entityName = (entity === "default") ? "Guruji" : entity;
    const { service, aspect } = getServiceAndRelatedAspect(entityName);

    const title = `Astro ${entityName.charAt(0).toUpperCase() + entityName.slice(1)}: ${service.charAt(0).toUpperCase() + service.slice(1)} Astrology Insights | HiAstro`;
    const description = `Free Chat with ${entityName.charAt(0).toUpperCase() + entityName.slice(1)} for expert ${service} astrology. Discover personalized insights, ${service} predictions, and guidance tailored just for you. Start your journey today!`;
    const keywords = `${service}, ${entityName}, ${service} astrologer, ${service} predictions, ${service} consultation, ${service} astrology`;

    return { title, description, keywords };
  };

  const metaTags = getMetaTags();
  

  return (

    <div className='resultsPage' style={{ padding: "2%" }}>
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
        <meta name="keywords" content={metaTags.keywords} />
        <meta property="og:title" content={metaTags.title} />
        <meta property="og:description" content={metaTags.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTags.title} />
        <meta name="twitter:description" content={metaTags.description} />
      </Helmet>

      {
        <div className='resultsPage__header'>
          <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }} className='resultsPage__header__prompt__entry'>
            <div style={{ display: "flex", alignItems: "center", margin: "5px" }} onClick={(_) => navigate("/")}>
              <ArrowBackIosIcon sx={{ marginRight: "10px" }} />

              <img
                src={getImageSrc(entity)}
                width={"40px"}
                style={{ borderRadius: "50%" }}
                alt="entity"
              />
              <div>
                <span style={{ fontWeight: "500", marginLeft: "10px", marginBottom:"5px", fontSize: "1.1rem", textTransform: "capitalize" }}>{entityName}</span>
                <span style={{ fontWeight: "400", marginLeft: "10px", fontSize: "0.8rem", textTransform: "capitalize", display:"block", opacity:"0.5", paddingTop:"3px" }}>{status}</span>
              </div>
            </div>
            
            {chatMode && <h1 onClick={(_) => { dispatch({ type: actionTypes.RESET_STATE });; navigate(`/chat/${entity}`); }} style={{ fontWeight: 500, opacity: "0.9", fontFamily: "'Roboto', sans-serif" }}>ChatDragon.ai</h1>}


            <RightSidebar />
          </div>
          
          <div style={{marginLeft:"5%", marginTop:"1%"}}>
            <p style={{fontSize:"0.7rem", opacity:0.5}}><i>100% Private Chat, No Data stored</i></p>
          </div>

        </div>
        
        // <Header userCred={null} displayAI={false} showLogin={false} />
      }

      <div className='resultsPage__body' ref={refToScrollableDiv}>

        <div className='resultsPage__ai__response'>

          {(

            displayConversationHistory
              .filter(u => u.role === "assistant" || u.role === "user")
              .map((u, index) => {
                if(u.content == "AstroSky") {
                  return ( <>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "10px" }}>
                      <div
                        style={{
                          background: "whitesmoke",
                          whiteSpace: "pre-line",
                          padding: "3%",
                          borderRadius: "10px",
                          maxWidth: "70vw"
                        }}
                        dangerouslySetInnerHTML={{ __html: `<div style="line-height:1.25rem;"><div></div> <div>My details:</div> <br/> <div> Date of Birth: ${formatDate(dob)} </div> <div>Time of Birth: ${tob}</div> <div>Place of Birth: ${city}</div><span id="current-time" style="font-size: 0.7rem; color: gray; flex-direction: row-reverse; display:flex; margin-top:5px;">
                        <i>${getCurrentTime()} </i></p></div>` }}
                      ></div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                      </div>
                    </div>
                    <br />
                  </>);
                }
                if (u.role == "user") {
                  return (
                    <>
                      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "10px" }}>
                        <div
                          style={{
                            background: "whitesmoke",
                            whiteSpace: "pre-line",
                            padding: "3.2%",
                            borderRadius: "10px",
                            maxWidth: "70vw",
                            lineHeight:"1.25rem"
                          }}
                          dangerouslySetInnerHTML={{ __html: u.content+=`<span id="current-time" style="font-size: 0.7rem; color: gray; flex-direction: row-reverse; display:flex;margin-top:5px;">
                          <i>${getCurrentTime()} </i></span>` }}
                        ></div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                        </div>
                      </div>
          
                    </>

                  )
                }





                return (<div key={index} className='ai__display'>
                  <div className='ai__display__assistant'>
                    {
                      <>
                        <br />
                        {/* <img src={getImageSrc(entity)} width={"65px"} style={{  borderRadius: "50%" }} />
                        <div style={{ whiteSpace: "pre-line", padding: "3%" }} dangerouslySetInnerHTML={{ __html: u.content }}></div> */}

                        <div style={{ display: "flex" }}>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            {/* <img 
                              src={getImageSrc(entity)} 
                              width={"45px"} 
                              style={{ borderRadius: "10px" }} 
                              alt="entity"
                            /> */}
                          </div>
                          <div
                            style={{ fontWeight: "400",  lineHeight:"1.25rem", whiteSpace: "pre-line", padding: "3.2%", borderRadius: "15px 15px 15px 0px", maxWidth: "70vw", backgroundColor: "bisque" }}
                            dangerouslySetInnerHTML={{ __html: u.content+=`<span id="current-time" style="font-size: 0.7rem; color: gray; flex-direction: row-reverse; display:flex; margin-top:5px">
                            <i>${getCurrentTime()} </i></span>` }}
                          ></div>
                        </div>

                        {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <Button
                            style={{
                              color: "black",
                              textTransform: "none",
                              display: "flex", // Use flex layout
                              flexDirection: "column", // Arrange children in a column
                              alignItems: "center", // Center-align items horizontally
                              justifyContent: "center", // Center-align items vertically
                            }}
                            onClick={async _ => {
                              try {
                                const url = "https://astrosky.co.in";
                                const toShare = `${u.content}, powered by, ${encodeURIComponent(url)}`
                                const whatsappUrl = `https://api.whatsapp.com/send?text=${toShare}`;
                                window.open(whatsappUrl, '_blank');
                                window.gtag('event', 'Share');
                              } catch (_) {
                                console.error("Failed to Copy");
                              }
                            }}>
                            <img src="/icons/whatsapp.png" alt="WhatsApp" style={{ width: "32px", textDecoration: "none" }} />
                          </Button>
                        </div> */}

                        {/* <hr style={{ marginTop: "1%", opacity: "0.3" }} /> */}

                        <br /> <br />
                      </>
                    }

                  </div>

                </div>

                )
              })
          )}
          {loading && <div className='ai__display'>
            <div className='ai__display__assistant'> <div className='resultsPage__ai__response__spinner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <AtomicSpinner style={{ width: '250px', height: '250px' }} />
            </div></div></div>
          }
        </div>
        {/* <br /> <br />
        <br /> <br /> */}
      </div>
      <div className='resultsPage__footer'>
        <Refine refToScrollableDiv={refToScrollableDiv} showQuestions={true} />
      </div>

    </div>
  );
}

export default Results;

