// import { useEffect } from "react";

// import { useStateValue } from "../statemanagement/StateProvider";
// import { actionTypes } from "../statemanagement/reducer";
// import { fetchHoroscopeData } from "../helper/fetchHelpers/fetchHelpers";
// import { LocationDisabled } from "@mui/icons-material";

// /**
//  * Pull from cache and update state
//  * based on updated state, fetch horoscope
//  */
// const UseBirthDetails = () => {

//     const [{horoscope, dob, tob, geo}, dispatch] = useStateValue();

//     useEffect(()=>{
        
//         const cityCache = localStorage.getItem("city");
//         let geoCache = localStorage.getItem("geo");

//         if(!!cityCache && !!geoCache){
//             const geo = JSON.parse(geoCache);
//             dispatch({type: actionTypes.SET_USER_GEO, geo:{lat:Number(geo["lat"]), lon:Number(geo["lon"])}});
//             dispatch({type: actionTypes.SET_USER_CITY, city: cityCache});   
//         }
    
//         const dob = localStorage.getItem("dob");
//         const tob = localStorage.getItem("tob");

//         if(!!dob && !!tob){
//             dispatch({type: actionTypes.SET_USER_DOB, dob: dob });
//             dispatch({type: actionTypes.SET_USER_TOB, tob: tob });
//         }
        
//         // const genderCache = localStorage.getItem("gender");
//         // dispatch({type: actionTypes.SET_GENDER, gender: genderCache});

//     },[])

//     useEffect(()=>{

//         if(!!geo && !!dob && !!tob){ //horoscope is a function of 
//            // console.log("Attempt to Fetch Horoscope");
//           //  alert("re-fetch");
//             fetchHoroscopeData(dob, tob, geo.lat, geo.lon, horoscope).then((h_d)=>{
//                 dispatch({type: actionTypes.SET_HOROSCOPE, horoscope: {...h_d, dob:dob, tob:tob, lat:geo.lat, lon:geo.lon}});
//             })

//         }
        
//     // },[]) //all of them have been set 
//     },[geo, dob, tob]) //all of them have been set 
// }

// export default UseBirthDetails;

import { useEffect } from "react";

import { useStateValue } from "../statemanagement/StateProvider";
import { actionTypes } from "../statemanagement/reducer";
import { fetchHoroscopeData } from "../helper/fetchHelpers/fetchHelpers";
import { LocationDisabled } from "@mui/icons-material";

/**
 * Pull from cache and update state
 * based on updated state, fetch horoscope
 */
const UseBirthDetails = () => {

    const [{horoscope, dob, tob, geo}, dispatch] = useStateValue();

    useEffect(()=>{
        
        const cityCache = localStorage.getItem("city");
        let geoCache = localStorage.getItem("geo");

        if(!!cityCache && !!geoCache){
            const geo = JSON.parse(geoCache);
            dispatch({type: actionTypes.SET_USER_GEO, geo:{lat:Number(geo["lat"]), lon:Number(geo["lon"])}});
            dispatch({type: actionTypes.SET_USER_CITY, city: cityCache});   
        }
    
        const dob = localStorage.getItem("dob");
        const tob = localStorage.getItem("tob");

        if(!!dob && !!tob){
            dispatch({type: actionTypes.SET_USER_DOB, dob: dob });
            dispatch({type: actionTypes.SET_USER_TOB, tob: tob });
        }
        
        const genderCache = localStorage.getItem("gender");
        dispatch({type: actionTypes.SET_GENDER, gender: genderCache})
    },[])

    useEffect(()=>{
        console.log(`Geo has changed or DOB has changed or TOB has changed`);
        if(!!geo && !!dob && !!tob){ 
            fetchHoroscopeData(dob, tob, geo.lat, geo.lon, horoscope).then((h_d)=>{
                dispatch({type: actionTypes.SET_HOROSCOPE, horoscope: {...h_d, dob:dob, tob:tob, lat:geo.lat, lon:geo.lon}});
            })
        }
    },[geo, dob, tob])
}

export default UseBirthDetails;