import { Button, Rating } from "@mui/material";
import { useStateValue } from "../statemanagement/StateProvider";
import { actionTypes } from "../statemanagement/reducer";
import { useNavigate } from "react-router-dom";
import "./Entity.css";


const Entity = ({ entityName, entityCode, entityDescription, entityImageSrc, app_category, entityRating, sampleQuestions, chatDisplay = "Chat", orders }) => {

    const [{ userCred, entity, horoscope, dob, tob, city }, dispatch] = useStateValue();
    const navigate = useNavigate();

    const clickEntity = () => {
        if (!dob || !tob || !city) {
            // console.log(`${JSON.stringify(horoscope)}`);
            // console.log(`${dob}`);
            // console.log(`${tob}`);
            // console.log(`${city}`);
            navigate("/edit");
            return;
        }

        window.gtag('event', entityCode);
        console.log(`Event: ${entityCode}`);

        if (entityCode != "guruji") {
            dispatch({ type: actionTypes.SET_ENTITY, entity: entityCode })
        }

        dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
        dispatch({ type: actionTypes.SET_LOADING, loading: true });

        navigate(`/chat/${entityCode}`);
    }

    return (<div className="entityContainer">

        <img className="entityImage"
            src={entityImageSrc} alt={entityName} onClick={((_) => clickEntity())} />


        <br /><br />

        <p style={{ fontWeight: "500", fontSize: "1.3rem", marginBottom: "5px" }} onClick={((_) => clickEntity())}  >{entityName} </p>

        <div style={{ color: "gray", display: "flex", flexDirection: "row", alignItems: "center", "justifyContent": "center", marginTop: "20px", marginBottom: "20px", fontWeight: "400", fontSize: "1rem" }} onClick={((_) => clickEntity())} >{entityDescription}</div>


        <Button className="discoverChatStyle" onClick={(e) => {

            if (!horoscope || !dob || !tob || !city) {
                navigate("/edit");
                return;
            }
           // alert(entityCode);
            window.gtag('event', entityCode);
            //  console.log(`Event: ${entityCode}`);

            if (entityCode != "guruji") {
                dispatch({ type: actionTypes.SET_ENTITY, entity: entityCode })
            }

            dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
            dispatch({ type: actionTypes.SET_LOADING, loading: true });

            navigate(`/chat/${entityCode}`);
        }}>{chatDisplay}</Button>

        <br /> <br />


        {/* { category == "ultra" &&  <Button className="discoverChatStyle" onClick={(e) => {

            if(!horoscope){
                navigate("/edit");
                return;
            }

            dispatch({type: actionTypes.SET_CATEGORY, category:"celeb"});
            dispatch({type: actionTypes.SET_ENTITY, entity:entityCode});
            dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
            dispatch({ type: actionTypes.SET_LOADING, loading: true });
            window.gtag('event', entityCode);
            navigate(`/chat/${entityCode}`);
        }}>Chat</Button> } */}


        <div style={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
            <Rating
                sx={{ marginTop: "10px !important", marginRight: "10px" }}
                name="simple-controlled"
                value={entityRating}
                precision={0.5}
                onChange={(event, newValue) => {
                }}

            />
            <p style={{ marginLeft: "10px", color: "gray", fontSize: "1rem", fontWeight: "400" }}> {orders} orders</p>
        </div> <br />


    </div>);
}

export default Entity;