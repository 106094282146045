import { signInWithCustomToken } from 'firebase/auth';
import { actionTypes } from '../statemanagement/reducer';
import { setDoc, serverTimestamp, doc, updateDoc, getDoc, collection, addDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';


export const saveUserQuestion = async (question, entity) => {
    try {
        console.log(`Saving in DB, entity: ${entity}, question: ${entity}`)
      const docRef = await addDoc(collection(db, 'questions'), {
        question: question,
        entity: entity,
        timestamp: serverTimestamp()
      });
      console.log("DOC WRITTEN");
      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  export const saveAutoGenQuestion = async (question) => {
    try {
        console.log("adding to user questions");
      const docRef = await addDoc(collection(db, 'autoquestions'), {
        question: question,
        timestamp: serverTimestamp()
      });
      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };