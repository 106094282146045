import { useEffect } from "react";
import axios from "axios";
import { actionTypes } from '../statemanagement/reducer';
import { useStateValue } from "../statemanagement/StateProvider"
import { getBasicChatLanguageModel, getWritingAssistantModel, getBasicChatLanguageModelAstro } from "../components/constants/helpers";
import { fetchHoroscope, fetchHoroscopeData, generateHoroscopeSignature } from "../helper/fetchHelpers/fetchHelpers";
import { useNavigate } from "react-router-dom";
import { getUrl } from "./envUrls";

let cancelSource;


const useAIService = (term, followup) => {

  const [{ conversationHistory, streaming, dob, tob, geo, city, entity, horoscope, gender }, dispatch] = useStateValue();

    
  //const prodUrl =  "http://localhost:8088";
  useEffect(() => {

    const fetch = async () => {
      
      //console.log(`Gender is: ${gender}`);
      dispatch({ type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: true });
      const response = await axios.post(`${getUrl()}/question`, { messages: conversationHistory, "entity":entity, "gender":gender });

      if (!!response["data"] && response["data"] != "") {
        dispatch({
          type: actionTypes.SET_NEXT_Q,
          next: response["data"].includes(":") ? response["data"].split(":")[1].trim() : response["data"]
        });
      }
      dispatch({ type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: false });
    }


    if (!streaming && conversationHistory.length > 0 && !!conversationHistory[conversationHistory.length - 1]["role"] && conversationHistory[conversationHistory.length - 1]["role"] == "assistant") {
      fetch();
    }

  }, [conversationHistory, streaming]);


  const gptService = async () => {



   //TRIAL - COUNT LOGIC 
    // const trialCount = localStorage.getItem("trialCount");
    // if(!trialCount){
    //   localStorage.setItem("trialCount", 0);
    // }else{
    //   localStorage.setItem("trialCount", (Number(trialCount)+1))
    // }


    // if(!userCred){
    //   if(!!trialCount && Number(trialCount)>=2){
    //     navigate("/signin");
    //   }
    // }

    // if(!!userCred && userCred.tier === "free"){
    //   if(!!trialCount && Number(trialCount)>=2){
    //     navigate("/profile");
    //   }
    // }

    let accumulatedResponse = ``;
    let topUrl = ``;
    
    const h_d = await fetchHoroscopeData(dob,tob,geo.lat,geo.lon, horoscope);
    const horoscopeToDispatch = {...h_d, dob:dob, tob:tob, lat:geo.lat, lon:geo.lon};
    dispatch({type: actionTypes.SET_HOROSCOPE, horoscope: horoscopeToDispatch}); 

    dispatch({
      type:  actionTypes.SET_STREAMING,
      streaming: true
    }); 
    
    const response = await fetch(`${getUrl()}/astro-service`,{ 
      method: "POST",
      headers: {
        Accept: "text/html",
        "Content-Type":"application/json"
      },
      body: JSON.stringify({ "messages": conversationHistory, "source":topUrl, "userDetail": {tob:tob, dob:dob, lat: geo.lat, lon: geo.lon, city: city}, "entity":entity, "horoscope":horoscopeToDispatch, "gender":gender })
    });

    if(!response.ok || !response.body){
      throw response.statusText;
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    let loopCount = 0;
    const spaUpdateInterval = 1; 
    
    // dispatch({
    //   type:  actionTypes.SET_STREAMING,
    //   streaming: true
    // }); 

    dispatch({
      type: actionTypes.SET_LOADING,
      loading: false
    }); 

    while (true) {
      const {value, done } = await reader.read();
      if(done) { 
        
       // accumulatedResponse+=amendReference;
      //  dispatch({type: actionTypes.SET_HOROSCOPE, horoscope: {...h_d, dob:dob, tob:tob, lat:geo.lat, lon:geo.lon}}); //proof of configuration
        dispatch({
          type: actionTypes.UPDATE_CONVERSATION_HISTORY,
          enrichedConversationHistory:[...conversationHistory, {role:"assistant", content:accumulatedResponse}]
        }) 
        dispatch({
          type:  actionTypes.SET_STREAMING,
          streaming: false
        }); 

        //console.log("Update in DB");
        

        break; 
      }
      const decodedChunk = decoder.decode(value, {stream: true});
      accumulatedResponse+=decodedChunk; 
      if(loopCount % spaUpdateInterval === 0){ 
        dispatch({
          type: actionTypes.UPDATE_CONVERSATION_HISTORY,
          enrichedConversationHistory:[...conversationHistory, {role:"assistant", content:accumulatedResponse}]
        }) 
      }
      loopCount++;
    }

    
    dispatch({
      type: actionTypes.UPDATE_CONVERSATION_HISTORY,
      enrichedConversationHistory:[...conversationHistory, {role:"assistant", content:accumulatedResponse}]
    }) ;
  
  }


  useEffect(() => {
    if (term.length > 0) {
      gptService();
    }
  }, [term]);


  useEffect(() => {
    if (followup.length > 0) {
      gptService();
    }
  }, [followup]); //"hi"
};



export default useAIService;
