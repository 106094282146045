import React from 'react';
import { Helmet } from 'react-helmet';

export const generateDynamicTitle = (app_category) => {
    let titleTxt = 'Free Astrology Chat-Powered by AI | HiAstro';

    switch (app_category) {
        case 'Kundli Matching':
            titleTxt = 'Perfect Match: Kundali Matching for Love & Marriage | HiAstro';
            break;
        case 'Marriage Predictions':
            titleTxt = 'Free Accurate Marriage Predictions with Your Kundali | HiAstro';
            break;
        case 'Love Predictions':
            titleTxt = 'Free Accurate Love Predictions for Your Relationship | HiAstro';
            break;
        case 'Career Predictions':
            titleTxt = 'Free Career Predictions by Date of Birth | HiAstro';
            break;
        case 'Wealth':
            titleTxt = 'Free Wealth Predictions: Discover Your future Wealth | HiAstro';
            break;
        case 'Numerology':
            titleTxt = 'Free Numerology: Your Path to Career Success Awaits | HiAstro';
            break;
        case 'Vastu':
            titleTxt = 'Free Vastu Tips for Harmony: Transform Your Space | HiAstro';
            break;
        case 'Feng Shui':
            titleTxt = 'Free Feng Shui Insights for a Prosperous Life | HiAstro';
            break;
        case 'Life Coach':
            titleTxt = 'Free Life Coach Predictions - Transform Your Future | HiAstro';
            break;  
        case 'Live':
            titleTxt = 'Free Live Predictions: Love, Career & More | HiAstro';
            break;
        default:
            titleTxt = 'Free Astrology Chat-Powered by AI | HiAstro';
    }
    return titleTxt;
};

export const getKeywordsByCategory = (app_category) => {
    //content="Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
    let keywords = "Free Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
    switch (app_category){
        case 'Kundli Matching':
            keywords = "Kundli Matching, HiAstro Kundali Report, Horoscope matching for marriage, Find your life partner, Kundli match online, Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;
        case 'Marriage Predictions':
            keywords = "Free Marriage Predictions, Marriage compatibility astrology, Personalized marriage prediction, Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;
        case 'Love Predictions':
            keywords = "Free Love Predictions, Romantic compatibility astrology ,future love life prediction, Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;
        case 'Career Predictions':
            keywords = "Free Career forecast by date of birth, Future career insights,Birth chart career analysis,Professional life astrology predictions, Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;
        case 'Wealth':
            keywords = "Free Wealth forecast by date of birth,Financial astrology, Financial future predictions,Astrology for wealth,Date of birth wealth prediction,Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;
        case 'Numerology':
            keywords = "Free Numerology predictions, professional development, numerology career analysis, numerological guidance, Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;
        case 'Vastu':
            keywords = "Free Vastu predictions, Vastu tips, positive energy, home harmony, Vastu for prosperity, Vastu insights, Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;
        case 'Feng Shui':
            keywords = "Free Feng Shui predictions, Feng Shui insights,Space harmony, Personal growth through Feng Shui, Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;
        case 'Life Coach':
            keywords = "Free Life Coach Predictions, Astrology for Life Coaching,Future Predictions,Success Coaching Astrology, Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;  
        case 'Live':
            keywords = "Free Live Predictions, Career astrology insights,Love life predictions,Live prediction astrology services, Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;
        default:
            keywords = "Free Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
    }
    return keywords;
}
export const generateDynamicDescription = (app_category) => {
    let descriptionTxt = "Get free, accurate predictions with HiAstro's AI-powered astrology for love, career, and marriage. Explore expert insights with Vastu, tarot, numerology, and detailed Kundali reports.";
    switch (app_category) {
        case 'Kundli Matching':
            descriptionTxt = 'Free Chat with a Kundli matching astrologer on HiAstro, an AI-powered astrology platform. Get instant answers to all your Kundli Milan questions from our expert AI astrologer.';
            break;
        case 'Marriage Predictions':
            descriptionTxt = 'Free Chat with a Marriage Predictions astrologer on HiAstro, an AI-powered astrology platform. Get instant answers to all your Marriage Predictions questions from our expert AI astrologer.';
            break;
        case 'Love Predictions':
            descriptionTxt = 'Free Chat with a Love Predictions astrologer on HiAstro, an AI-powered astrology platform. Get real-time insights into your love life from our expert AI astrologer.';
            break;
        case 'Career Predictions':
            descriptionTxt = 'Free Chat with a Career Predictions astrologer on HiAstro, an AI-powered astrology platform. Get career advice and predictions from our expert AI astrologer.';
            break;
        case 'Wealth':
            descriptionTxt = 'Free Chat with a Wealth Predictions astrologer on HiAstro, an AI-powered astrology platform. Get insights into your financial future from our expert AI astrologer.';
            break;
        case 'Numerology':
            descriptionTxt = 'Free Chat with a Numerology expert on HiAstro, an AI-powered astrology platform. Discover your numerology numbers and predictions from our expert AI astrologer.';
            break;
        case 'Vastu':
            descriptionTxt = 'Free Chat with a Vastu Predictions astrologer on HiAstro, an AI-powered astrology platform. Get Vastu guidance and predictions from our expert AI astrologer.';
            break;
        case 'Feng Shui':
            descriptionTxt = 'Free Chat with a Feng Shui expert on HiAstro, an AI-powered astrology platform. Get Feng Shui tips and predictions from our expert AI astrologer.';
            break;
        case 'Life Coach':
            descriptionTxt = 'Free Chat with a Life Coach on HiAstro, an AI-powered astrology platform. Get tips and predictions from our expert AI astrologers.';
            break;  
        case 'Live':
            descriptionTxt = 'Get Live Free Astrology Predictions: Love, Career & More | HiAstro ';
            break;
        default:
            descriptionTxt = "Get free, accurate predictions with HiAstro's AI-powered astrology for love, career, and marriage. Explore expert insights with Vastu, tarot, numerology, and detailed Kundali reports.";
    }
    return descriptionTxt;
};

const MetaComponent = ({ app_category }) => {
    return (
        <Helmet>
            <title>{generateDynamicTitle(app_category)}</title>
            <meta name="description" content={generateDynamicDescription(app_category)} />
            <meta
                name="keywords"
                content = {getKeywordsByCategory(app_category)}
            />
        </Helmet>
    );
};

export default MetaComponent;