export const getImageSrc = (entity) => {
    switch(entity) {
        case "jaspal":
            return "https://astrosky.blob.core.windows.net/astrosky/entity-002.webp";
        case "guruji":
            return "https://astrosky.blob.core.windows.net/astrosky/babaji.webp";
        case "raj":
            return "https://astrosky.blob.core.windows.net/astrosky/raj_closeup.webp";
        case "preeti":
                return "https://astrosky.blob.core.windows.net/astrosky/entity-001.webp";
        case "ria":
                return "https://astrosky.blob.core.windows.net/astrosky/ria-3.webp";
        case "romila": 
                return "https://astrosky.blob.core.windows.net/astrosky/mam.webp";
        case "vikramjeet":
                return "https://astrosky.blob.core.windows.net/astrosky/vikramjeet.webp";
        case "sunny":
                return "https://astrosky.blob.core.windows.net/astrosky/sunny.webp";
        case "subra":
                return "https://astrosky.blob.core.windows.net/astrosky/subra.webp";
        case "venkat":
                return "https://astrosky.blob.core.windows.net/astrosky/venkat.webp";
        case "swami":
                return "https://astrosky.blob.core.windows.net/astrosky/swami.webp";
        case "vijayalakshmi":
                return "https://astrosky.blob.core.windows.net/astrosky/vijayalakshmi.webp";
        case "vivek":
                return "https://astrosky.blob.core.windows.net/astrosky/vivek.webp";
        case "chandrikaa":
                return "https://astrosky.blob.core.windows.net/astrosky/chandrikaa.webp";
        case "naina":
                return "https://astrosky.blob.core.windows.net/astrosky/naina.jpg";
        case "prem":
                return "https://astrosky.blob.core.windows.net/astrosky/prem.jpg";
        case "ajay":
                return "https://astrosky.blob.core.windows.net/astrosky/ajay.webp";
        case "hemant":
                return "https://astrosky.blob.core.windows.net/astrosky/Hemant.jpg";
        case "raviraj":
                return "https://astrosky.blob.core.windows.net/astrosky/raviraj.jpg";
        case "ivana":
                return "https://astrosky.blob.core.windows.net/astrosky/ivana.webp";       
        case "hrithik":
                return "https://astrosky.blob.core.windows.net/astrosky/hrithik.webp";    
        case "priyanka":
                return "https://astrosky.blob.core.windows.net/astrosky/Rekha.jpg";
        case "vidya":
                return "https://astrosky.blob.core.windows.net/astrosky/vidya-2.jpeg";    
        case "madhav":
                return "https://astrosky.blob.core.windows.net/astrosky/madhav.jpeg"; 
        case "karan":
                return "https://astrosky.blob.core.windows.net/astrosky/karan.webp"; 
        case "pooja":
                return "https://astrosky.blob.core.windows.net/astrosky/Pooja.jpg"; 
        case "moniika":
                return "https://astrosky.blob.core.windows.net/astrosky/moniika.webp";
        case "prajakta":
                return "https://astrosky.blob.core.windows.net/astrosky/prajakta.jpg";   
        case "aakash":
                return "https://astrosky.blob.core.windows.net/astrosky/aakash.jpg"; 
        case "aditya":
                return "https://astrosky.blob.core.windows.net/astrosky/Aditya.jpg";  
        case "sonam":
                return "https://astrosky.blob.core.windows.net/astrosky/Sonam.jpg";     
        case "arun":
                return "https://astrosky.blob.core.windows.net/astrosky/Arun.jpg";  
        case "chitra":
                return "https://astrosky.blob.core.windows.net/astrosky/Chitra.jpg";  
        case "ashok":
                return "https://astrosky.blob.core.windows.net/astrosky/ashok.jpg";
        case "geetika":
                return "https://astrosky.blob.core.windows.net/astrosky/geetika.png";
        case "akshay":
                return "https://astrosky.blob.core.windows.net/astrosky/akshay.jpg";
        case "rajiv":
                return "https://astrosky.blob.core.windows.net/astrosky/Sanjay.jpg";
        default:
            return "https://astrosky.blob.core.windows.net/astrosky/entity-001.webp";
    }
}