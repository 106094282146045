import React, { useEffect, useState } from 'react';
import { getBlogs } from '../../strapiService';
import './blogs.css';
import { Link } from 'react-router-dom';
import AtomicSpinner from 'atomic-spinner';
import { useStateValue } from '../../statemanagement/StateProvider';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [{language}] = useStateValue();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await getBlogs(language);
        if (response) {
          setBlogs(response);
        } else {
          console.error('Invalid response structure', response);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };
    fetchBlogs();
  }, [language]);

  return (
    <div className="blogs-page">
      <div className="main-content">
        <main className="content">
          {blogs.length > 0 ? (
            <div className="blog-list">
              {blogs.map((blog) => {
                const blogThumbnailData = blog.attributes.thumbnail?.data;
                const blogThumbnailUrl = blogThumbnailData && blogThumbnailData.length > 0
                  ? blogThumbnailData[0].attributes.url
                  : '';

                return (
                  <Link key={blog.id} to={`/blog/${blog.attributes.path}`} className="blog-item">
                    <div className="blog-image-container">
                      {blogThumbnailUrl && (
                        <img 
                          src={blogThumbnailUrl} 
                          alt={blog.attributes.title} 
                          className="blog-image"
                        />
                      )}
                    </div>
                    <div className="blog-details">
                      <h2 className="blog-title">{blog.attributes.title}</h2>
                      <div className="blog-meta">
                        <span className="blog-author">Vandana</span>
                        <span className="blog-date">{new Date(blog.attributes.createdAt).toLocaleDateString()}</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <div className='spinner'>
              <AtomicSpinner/>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Blogs;
