import './App.css';
import Results from './pages/Results';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivacyPolicy from "./pages/static/Privacy.js";
import TermsOfService from './pages/TermsOfService';
import Cancellation from './pages/Cancellation';
import Custom from './pages/Custom';
import Business from './pages/Business';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Shop from './pages/Shop';
import Order from './pages/Order';
import AstroShop from './pages/AstroShop';
import Dictaphone from './Dictaphone';
import Feedback from './pages/Feedback';
import Profile from './pages/Profile';
import Discover from './pages/Discover';
import EditHoroscopeDetails from './pages/EditHoroscopeDetails';
import SignInHeadless from './pages/SignInHeadless';
import Matrimony from './pages/Matrimony';
import Reports from './pages/Reports';
import WebLayout from './components/webComponents/webLayout';
import BlogDetail from './pages/blogs/blogDetails';
import Blogs from './pages/blogs/blogs';
import Refer from './pages/Refer';
import ContentDetails from './pages/content/contentDetails';
import AboutUs from './pages/static/AboutUs.js';
import ContactUs from './pages/static/ContactUs.js';
import { useAuth } from './statemanagement/authContext.js';
import UserLogin from './components/userLogin/userLogin.js';
import WeeklyView from './pages/WeeklyView.js';
import UseBirthDetails from './pages/UseBirthDetails.js';
import { useStateValue } from './statemanagement/StateProvider.js';
import { actionTypes } from './statemanagement/reducer.js';
import { useEffect } from 'react';
import { SplashScreen } from '@capacitor/splash-screen';

function App() {

  const [{}, dispatch] = useStateValue();
  //UserListener(); 
  UseBirthDetails();
  const { user } = useAuth();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    dispatch({ type: actionTypes.SET_LANGUAGE, language: savedLanguage });
  }, []);

  useEffect(() => {
    const showSplashScreen = async () => {
      await SplashScreen.show(); // Show splash screen

      // Wait for 10 seconds
      setTimeout(async () => {
        await SplashScreen.hide(); // Hide splash screen
      }, 2500);
    };

    showSplashScreen();
  }, []);

  return (
   
      <div className="app">
         <LocalizationProvider dateAdapter={AdapterDayjs} >
              <Router>
                    <Routes>
                      <Route exact path="/auth/login" element={!user ? <UserLogin />: <Navigate to="/edit"/>} />
                      <Route exact path="/chat/:entityCode" element={<Results />} />
                      <Route exact path="/privacy" element={<WebLayout><PrivacyPolicy/></WebLayout>} />
                      <Route exact path="/feedback" element={<Feedback/>} />
                      <Route exact path='/refer' element={<Refer/>} />
                      <Route exact path='/weekly' element={<WeeklyView/>} />
                      <Route exact path="/terms-of-service" element={<TermsOfService />} />
                      <Route exact path="/cancellation" element={<Cancellation />} />
                      <Route exact path="/custom" element={<Custom />} />
                      <Route exact path='/astroshop' element={<AstroShop/>}/>
                      <Route exact path='/business' element={<Business/>} /> 
                      <Route exact path="/shop" element={<Shop/>}/>
                      <Route exact path="/order" element={<Order/>}/>
                      <Route exact path="/mic" element={<Dictaphone/>}/>
                      <Route exact path="/profile" element={<Profile />}/>
                      <Route exact path='/' element={<Discover/>}/>
                      <Route exact path='/discover' element={<Discover/>}/>
                      <Route exact path='/edit' element={<EditHoroscopeDetails/>}/>
                      <Route exact path="/signin" element={<SignInHeadless/>} />
                      <Route exact path="/matrimony" element={<Matrimony/>}/>
                      <Route exact path="/reports" element={<Reports/>}/>
                      <Route exact path="/:path" element={<WebLayout><ContentDetails /></WebLayout>} />
                      <Route path="/blog/:path" element={<WebLayout><BlogDetail/></WebLayout>} />
                      <Route path="/blogs" element={<WebLayout><Blogs/></WebLayout>} />
                      <Route path="/about-us" element={<WebLayout><AboutUs/></WebLayout>}/> 
                      <Route path="/contact-us" element={<WebLayout><ContactUs/></WebLayout>}/> 
                      <Route exact path="/love-predictions" element={<Discover />} /> 
                      <Route exact path="/career-predictions" element={<Discover />} /> 
                      <Route exact path="/numerology" element={<Discover />} /> 
                      <Route exact path="/live" element={<Discover />} /> 
                      <Route exact path="/personal-reports" element={<Discover />} /> 
                      <Route exact path="/wealth" element={<Discover />} /> 
                      <Route exact path="/vastu" element={<Discover />} /> 
                      <Route exact path="/feng-shui" element={<Discover />} /> 
                      <Route exact path="/all" element={<Discover />} /> 
                      <Route exact path="/marriage-predictions" element={<Discover/>}/>
                      <Route exact path="/new-entities" element={<Discover/>}/>
                      <Route exact path="/kundli-matching" element={<Discover/>}/>
                      <Route exact path="/life-coach" element={<Discover/>}/>
                    </Routes>
              </Router>
              </LocalizationProvider>
      </div>
  );
}

export default App;

